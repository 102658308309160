import React from 'react'
import { Helmet } from "react-helmet"
import { useIntl } from "gatsby-plugin-intl"

const SEO = ({ title, description, robots, openGraph, canonical }) => {
  const intl = useIntl()

  return (
    <Helmet>
      <title>{title}</title >
      <meta name="description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{(title.charAt(0).toUpperCase() + title.substring(1, title.length).toLowerCase()) + " | Ground4Heat"}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charset="UTF-8" />
      <meta name="robots" content={robots} />
      <meta property="og:url" content={openGraph.url} />
      <meta property="og:image" content={openGraph.image} />
      <meta property="og:locale" content="ro_RO" />
      <meta property="og:type" content={openGraph.type} />
      <meta property="og:title" content={openGraph.title} />
      <meta property="og:description" content={openGraph.description} />
      <meta property="og:locale" content={intl.locale === "ro" ? "ro_RO" : "en_GB"} />
      <meta property="og:site_name" content="Ground4Heat" />
      <link rel="canonical" href={canonical} />
    </ Helmet>
  )
}

export default SEO
