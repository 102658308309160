import React, { useEffect } from "react"
import SEO from "../components/SEO/SEO"
import TagManager from "../components/TagManager/TagManager"
import logo from "../images/img.webp"
import styles from "./global.module.scss"

export default function Home() {
  useEffect(() => {
    setTimeout(() => {
      window.location.assign("home")
    }, 1100)
  }, [])

  return (
    <div>
      <TagManager />
      <SEO
        title="Pompe de caldura profesionale Oradea"
        description="Alege incalzirea eficienta prin pompe de caldura iDM Austria. Instalatii, foraje, automatizari si sisteme de ventilatie."
        robots="index, follow"
        canonical="https://g4h.ro/"
        openGraph={{ url: "https://www.g4h.ro/", type: "website", image: "https://g4h.ro/static/efaf3e54cff1491442342f47dd157102/65e33/img.png", title: "Pompe de caldura profesionale Oradea", description: "Alege incalzirea eficienta prin pompe de caldura iDM Austria. Instalatii, foraje, automatizari si sisteme de ventilatie." }}
      />
      <div className={styles.index_page_anim}>
        <img id={styles.logo_image} src={logo} alt="Logo" />
      </div>
    </div>
  )
}