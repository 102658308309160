import React from 'react'
import { Helmet } from 'react-helmet'

const TagManager = () => {
    return (
        <>
            <Helmet>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-DSF042NYJY"></script>
                <script>{`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){
                        dataLayer.push(arguments)
                    }
                    gtag('js', new Date());

                    gtag('config', 'G-DSF042NYJY'); `}
                </script>
            </Helmet>
        </>
    )
}

export default TagManager